const commonObj = {
    // inchargeType: {
    //     t5: [5048, 5042, 5041, 5039, 5047],
    //     t6: [5036, 5035, 5030, 5022, 5023, 5031, 5049, 5025, 5033, 5034, 5032],
    //     t8: [5026, 5044, 5043],
    //     t9: [5088, 5045, 5046],
    //     t10: [5028, 5037],
    //     t11: [5086, 5038, 5052],
    //     t12: [5029],
    //     t13: [5050],
    // },
    inchargeType: {
        t5: [45,46,37,38,39,40],
        t6: [34,33,28,20,21,29,47,22,23,31,32,30],
        t8: [41,24,42],
        t9: [43,44, 25],
        t10: [26,35],
        t11: [49,36,50],
        t12: [27],
        t13: [48],
    }
};

export default commonObj;