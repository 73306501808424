import React, { Suspense, useEffect, useState } from 'react';
import SiteLoader from './views/components/SiteLoader';
import commonObj from './commonObj';
import { message } from 'antd';
import service from './services/user';
import { BrowserRouter } from 'react-router-dom';
const Layout = React.lazy(() => import('./views/layout'));
const LayoutDealer = React.lazy(() => import('./views/layout/index-dealer'));
const Login = React.lazy(() => import('./views/login'));

export default function App() {
	const [isLoggedin, setIsLoggedin] = useState(false);
	const [ajxRequesting, setAjxRequesting] = useState(false);

	useEffect(() => {
		setAjxRequesting(true);
		service.validateToken().then(res => {
			setIsLoggedin(true);
			commonObj.userType = res.data.type;
			commonObj.dealerCode = res.data.dealerCode;
			commonObj.adminRights = res.data.adminRights || [];
			commonObj.name = res.data.firstName + ' ' + res.data.lastName;
			commonObj.avatar = res.data.avatar;
			// message.success(res.message);
		}).catch(err => {
			setIsLoggedin(false);
			window.localStorage.clear();
			message.error('Unauthorized. Login Again!!');
		}).finally(() => {
			setAjxRequesting(false);
		});
	}, []);

	return (
		<BrowserRouter>
			{
				!ajxRequesting
					? isLoggedin
						? <Suspense fallback={<SiteLoader />}> {commonObj.userType === "Super Admin" ? <Layout /> : <LayoutDealer />} </Suspense>
						: <Suspense fallback={<SiteLoader />}><Login /></Suspense>
					: <SiteLoader />
			}
		</BrowserRouter>
	);
};